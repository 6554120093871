import React, {useState} from 'react';
import {useLocation} from '@reach/router';
import {usePageContent} from 'src/functionality/content-provider';
import ExpenditureSurplus from 'images/corporate/expenditude-surplus.png';
import RevenueByProgram from 'images/corporate/program-revenue.png';
import PropTypes from 'prop-types';
import {Col, Container, Row} from 'react-bootstrap';
import Markdown from 'react-markdown';
import ArbiasButton from 'src/components/functional/arbias-button';
import ContentTag from 'src/components/functional/content-tag';
import {H3, H5, H6} from 'src/components/functional/headings';
import MoreAboutUs from 'src/components/functional/more-about-us-section';
import Page from 'src/components/functional/page';
import Tile from 'src/components/functional/tile';
import Spacer from 'src/components/graphical/spacer';
import TriangleCurve from 'src/components/graphical/triangle-curve';
import usePartners from 'src/functionality/partners-provider';
import HeroBackground from 'src/images/hero.jpg';
import {breakpoints} from 'src/styles/breakpoints';
import {theme} from 'src/styles/theme';
import styled from 'styled-components';
import ContentBlock from 'src/components/functional/content-block';
import {useFeatureFlags} from 'src/functionality/flags-provider';
import {getResource, useResources} from 'src/functionality/resources-provider';
import StrategicObjectivesGraphic from 'images/corporate/wheel.svg';

const HeroRow = styled(Row)`
  @media ${breakpoints.sm} { 
    flex-direction: column-reverse;
  }
`;

const HeroProfile = styled.div`
  background-color: ${theme.xlight};
  border-radius: 2px;
  @media ${breakpoints.sm} { 
    margin: 0 5vw;
    padding: 2.5em;
    margin-bottom: 2.5em;
    border-radius: 0;
  }
`;

const HeroProfileText = styled.div`
  padding: 0.5em 1em;
`;

const ExecutiveSummary = styled.div`
  box-shadow: 0 0 3rem 0 rgba(0, 0, 0, 0.08);
  padding: 5em 2.5vw;
  background-color: ${theme.xlight};
  border-radius: 2px;
  width: 70%;
  
  @media ${breakpoints.lg} { 
    width: 100%;
  }
  
  @media ${breakpoints.sm} { 
    border-radius: 0;
    margin: 5em 5vw;
  }
`;

const ExecutiveSummaryWrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
  
`;

const Hero = styled.div`
  display: flex;
  flex-direction: row;
  padding: 5em 0;
  
  @media ${breakpoints.sm} { 
    flex-direction: column-reverse;
    padding: 0;
  }
`;

const HeroWrapper = styled.section`
  width: 100vw;
  background-image: url(${HeroBackground});
  background-size: cover;
  background-position: top left;
`;
const NowSection = styled.section`
  & > .container{
    margin-bottom: -12vh;
  }
  @media ${breakpoints.lg} { 
    & > .container{
      margin-bottom: -6vh;
    }
  }
  @media ${breakpoints.md} { 
    & > .container{
      margin-bottom: 0;
    }
  }
`;
const FutureSection = styled.section`
  
`;

const CenteredDocument = styled.div`
  display: flex;
  justify-content: center;
`;

const PartnersSection = styled.section`
  padding: 2.5em 0;
  border-top: 1px solid ${theme.muted};
  border-bottom: 1px solid ${theme.muted};
`;

const PartnersLogo = styled.a`
  display: block;
  text-decoration: none;
  text-align: center;
  
  & h6{
    transition: all 0.2s ease;
  }
  
  &:hover h6{
    color: ${theme.primary}!important;
  }
  
  & img{
    filter: grayscale(100%);
    width: auto;
    max-height: 10vh;
  }
`;

const ObjectiveSection = styled(Row)`
  display: flex;
`;

const ObjectiveKey = styled.div`
  display: block;
  margin-left: -2em;
`;

const Objective = styled(Col)`
  display: flex;
  flex-direction: row;
`;

const ObjectiveText = styled.div`
  margin-left: 2em;
`;

const ShowStrategicObjectivesButton = styled.button`
  font-size: 2em!important;
  &:hover{
    color: ${theme.primary};
  }
  &:active{
    color: ${theme.darkPrimary};
  }
`;

const StrategicObjectivesImg = styled.img`
  max-width: max(50vw, 40em);
  @media ${breakpoints.sm} { 
    display: none;
  }
`;

const StrategicObjectivesTeaser = styled(Row)`
  background-color: ${theme.mediumGray};
  padding: 1em 0;
  margin-bottom: 1em;
`;

const AnnualReportsSection = styled.section``;

const CorporatePage = ({pageContext}) => {
  const location = useLocation();
  const content = usePageContent(location.pathname, pageContext.content);
  const partners = usePartners();
  // const flags = useFeatureFlags(pageContext.flags); //TODO: << implement this
  const flags = useFeatureFlags();
  const [reports] = useResources('Annual-reports');
  const [showStrategicObjectives, setShowStrategicObjectives] = useState(false);

  return (
    <Page>
      <HeroWrapper>
        <Container fluid="md" style={{padding: 0}}>
          <Hero>
            <HeroRow>
              <Col sm={12} md={5} lg={4} xl={3}>
                {flags.Corporate_EnableHeroProfile && (
                  <HeroProfile>
                    <img
                      className="d-none d-md-block"
                      src="https://picsum.photos/225/300"
                      alt="TODO:"
                    />
                    <HeroProfileText>
                      <ContentBlock
                        header={(props) => <h3 {...props} />}
                        content={content}
                        contentWrapper={(props) => (
                          <>
                            <span className="small text-muted">
                              CEO: arbias Ltd.
                            </span>
                            <div {...props} />
                          </>
                        )}
                        tag="C01"
                        linkText="More about John"
                        link={(props) => (
                          <ArbiasButton
                            {...props}
                            small
                            to="/about/people/#john-eyre"
                          />
                        )}
                      />
                      <h3>John Eyre</h3>
                      <ContentTag>C01</ContentTag>
                      <span className="small text-muted">CEO: arbias Ltd.</span>
                      <Markdown>
                        Lorem ipsum dolor sit amet consectetur, adipisicing
                        elit. labore doloremque ad fugit fugiat expedita minus
                        voluptate dicta, libero corrupti cupiditate earum
                        similique vero!
                      </Markdown>
                      <ArbiasButton small to="/about/people/#john-eyre">
                        More about John
                      </ArbiasButton>
                    </HeroProfileText>
                  </HeroProfile>
                )}
              </Col>
              <Col sm={12} md={7} lg={8} xl={9}>
                <ExecutiveSummaryWrapper>
                  <ExecutiveSummary className="lg-w-100">
                    <ContentBlock header={H3} content={content} tag="C02a" />
                    {!flags.Corporate_EnableHeroProfile && (
                      <p>- John Eyre, arbias Chief Executive Officer</p>
                    )}
                  </ExecutiveSummary>
                </ExecutiveSummaryWrapper>
              </Col>
            </HeroRow>
          </Hero>
        </Container>
      </HeroWrapper>
      <Spacer height={8} />
      <NowSection>
        <Container>
          <Row>
            <Col lg={6} sm={12}>
              <ContentBlock
                header={(props) => <H3 {...props} />}
                content={content}
                tag="C03b"
              />
            </Col>
            <Col lg={6} sm={12}>
              <H5 style={{color: '#d14c11'}} noUnderline>
                Revenue by Program
              </H5>
              <img
                src={RevenueByProgram}
                alt="arbias Expenditure and surplus table"
              />
              <Spacer height={4} />
              <H5 style={{color: '#d14c11'}} noUnderline>
                Expenditure and surplus proportions
              </H5>
              <img
                src={ExpenditureSurplus}
                alt="arbias Expenditure and surplus proportions pie chart"
              />
            </Col>
          </Row>
          <Spacer height={4} />
          {/* TODO: */}
          <ArbiasButton
            onClick={() =>
              getResource(reports[0].url).then((url) => window.open(url))
            }
          >
            Read most recent annual report
          </ArbiasButton>
        </Container>
        <TriangleCurve right />
      </NowSection>
      <FutureSection>
        <Spacer height={8} />
        <Container>
          <ContentBlock
            header={(props) => (
              <>
                <H3 {...props} />
                <CenteredDocument>
                  <Tile
                    isRounded
                    type="document"
                    onClick={() =>
                      // eslint-disable-next-line max-len
                      getResource(
                          'resources/Policies-plans-and-procedures/Strategic-Plan-2021-to-2023.pdf',
                      ).then((url) => window.open(url))
                    }
                    title="Strategic Plan"
                  />
                </CenteredDocument>
                <Spacer height={4} />
              </>
            )}
            content={content}
            tag="C04a"
          />
          <StrategicObjectivesTeaser>
            <Col sm={11}>
              <ContentBlock
                header={(props) => <H3 noUnderline {...props} />}
                content={content}
                tag="C04b"
              />
            </Col>
            <Col sm={1}>
              <ShowStrategicObjectivesButton
                className="plain-button"
                onClick={() =>
                  setShowStrategicObjectives(!showStrategicObjectives)
                }
              >
                {showStrategicObjectives ? (
                  <i className="fa fa-angle-up" />
                ) : (
                  <i className="fa fa-angle-down" />
                )}
              </ShowStrategicObjectivesButton>
            </Col>
          </StrategicObjectivesTeaser>
          {showStrategicObjectives && (
            <>
              <ObjectiveSection>
                {['C05a', 'C05b', 'C05c', 'C05d', 'C05e', 'C05f', 'C05g'].map(
                    (tag, key) => (
                      <Objective key={1} sm={12} md={6} lg={4}>
                        <ObjectiveKey>
                          <strong>{(key + 1).toString().padStart(2, '0')}</strong>
                        </ObjectiveKey>
                        <ObjectiveText>
                          <ContentBlock
                            header={(props) => <H5 noUnderline {...props} />}
                            content={content}
                            tag={tag}
                          />
                        </ObjectiveText>
                      </Objective>
                    ),
                )}
              </ObjectiveSection>
              <StrategicObjectivesImg
                src={StrategicObjectivesGraphic}
                alt="arbias strategic objectives process"
              />
            </>
          )}
        </Container>
      </FutureSection>
      {flags.Corporate_EnablePartnersSection && (
        <PartnersSection>
          <Container>
            <H3>Partners</H3>
            <Spacer height={2} />
            <Row>
              {partners &&
                partners.map((partners, key) => {
                  return (
                    <Col key={key}>
                      <PartnersLogo href={partners.link}>
                        <img src={partners.imageUrl} alt={partners.name} />
                        <H6 noUnderline className="text-muted">
                          {partners.name}
                        </H6>
                      </PartnersLogo>
                    </Col>
                  );
                })}
            </Row>
          </Container>
        </PartnersSection>
      )}
      <Spacer height={4} />
      <AnnualReportsSection>
        <Container>
          <H3>Annual Reports</H3>
          <Row>
            {reports?.slice(0, 4)?.map((report, key) => (
              <Tile
                key={key}
                isRounded={true}
                type="document"
                onClick={() =>
                  getResource(report.url).then((url) => window.open(url))
                }
                title={report.title}
              />
            ))}
          </Row>
          <ArbiasButton to="/about/resources#Annual-reports">
            See all &gt;
          </ArbiasButton>
        </Container>
      </AnnualReportsSection>
      <Spacer height={4} />
      <section>
        <Container>
          <Row>
            <Col>
              <ContentBlock
                header={(props) => <H3 {...props} />}
                content={content}
                tag="C07"
                linkText="Governance"
                link={
                  // eslint-disable-next-line max-len
                  (props) => (
                    <ArbiasButton
                      {...props}
                      to="/about/people/management-and-advisors#governance/"
                    />
                  )
                }
              />
            </Col>
            <Col>
              <ContentBlock
                header={(props) => <H3 {...props} />}
                content={content}
                tag="C06"
                linkText="Documents &amp; Resources"
                link={(props) => (
                  <ArbiasButton {...props} to="/about/resources/" />
                )}
              />
            </Col>
          </Row>
        </Container>
      </section>
      <Spacer height={8} />
      <MoreAboutUs />
    </Page>
  );
};

CorporatePage.propTypes = {
  pageContext: PropTypes.shape({
    content: PropTypes.any,
  }),
};

export default CorporatePage;


