import {useEffect, useState} from 'react';
import {API} from 'aws-amplify';
import {listPartners} from 'src/graphql/content';

/**
 * Returns the partners
 * @return {object} an object with multiple partners
 */
export default function usePartners() {
  const [partners, setPartners] = useState();

  useEffect(() => {
    if (!partners) {
      (async () => {
        const data = await API.graphql({
          query: listPartners,
        });
        setPartners(data.data.listPartners.items);
      })();
    }
  });
  return partners;
}
